import React, { useEffect, useState } from "react";
import { Container, Form, Button, Alert, ButtonGroup } from "react-bootstrap";
import { useAuth } from "../../firebaseContext";
import { useNavigate, useLocation } from "react-router-dom";

export default function UserProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state.email;
  const { getUserData } = useAuth();

  const [show, setshow] = useState(false);
  const [Data, setData] = useState(null);

  useEffect(async () => {
    const data = await getUserData(email);
    // console.log(data.data());
    setData(data.data());
    // console.log(data.data());
    setshow(true);
  }, []);
  return (
    <Container fluid = "lg" className="p-2">
      <Alert>
        You can only view user profile at this moment but this can be changed.
      </Alert>
      <Alert>
        Details will update once the user logs back in.
      </Alert>
      {show ? (
        <>
          <Form className="p-2">
            <Form.Group>
              <Form.Text>Email address</Form.Text>
              <Form.Control type="email" value={Data.Email} disabled></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Text>UID</Form.Text>
              <Form.Control type="text" value={Data.UID} disabled></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Text>Display name</Form.Text>
              <Form.Control type="text" value={Data.Display} disabled></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Text>Email verified?</Form.Text>
              <Form.Control type="boolean" value={Data.Verified} disabled></Form.Control>
            </Form.Group>
            <ButtonGroup className="w-100 py-3">
                <Button onClick={e=>{navigate(-1)}} variant="primary">Go Back</Button>
            </ButtonGroup>
          </Form>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
}
