import React, { useEffect, useState } from "react";
import { Container, Button, Table } from "react-bootstrap";
import { listAll, ref } from "firebase/storage";
import { storage } from "../../firebase";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../firebaseContext";

export default function ViewFiles() {
  const listRef = ref(storage, "Viewable Files");
  const [folders, setfolders] = useState([]);
  const navigate = useNavigate();
  const { admin } = useAuth();

  useEffect(() => {
    setfolders([]);
    // const rootList = ref(storage);

    listAll(listRef)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          setfolders((folders) => [...folders, [folderRef.name, folderRef]]);
        });
      })

      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  }, []);

  function handleSelectFolder(reference) {
    // console.log(reference);
    navigate("/file/" + reference.name, {
      state: {
        fullPath: reference.fullPath,
      },
    });
  }
  function handleAdminButton(e) {
    e.preventDefault();
    if (e.target.id == "addFolder") {
      navigate("/addFolder", {
        state: {
          path: listRef.fullPath,
        },
      });
    }
  }

  return (
    <>
      <Container fluid="lg" className="p-2 border">
        {/* <SubNav /> */}

        {admin ? (
          <Container fluid className="d-flex justify-content-center p-1">
            <Button
              variant="red"
              onClick={handleAdminButton}
              size="sm"
              id="addFolder"
              className="mx-2 my-1 text-truncate w-40"
            >
              Create&nbsp;a&nbsp;folder&nbsp;+&nbsp;Add&nbsp;files&nbsp;here
            </Button>
          </Container>
        ) : (
          ""
        )}
        <Container fluid className="p-2">
          {/* {folders[0] ? <h3 className="mb-3">Folders </h3> : ""} */}
          <Table className="text-center">
            <thead>
              <tr>
                <th>File collections</th>
              </tr>
            </thead>
            <tbody>
              {folders.map(function (e) {
                return (
                  <tr>
                    <td>
                      <Button
                        variant="secondary"
                        key={e.Id}
                        className="p-1 w-100"
                        onClick={(event) => {
                          event.preventDefault();
                          handleSelectFolder(e[1]);
                        }}
                      >
                        <FontAwesomeIcon icon={faFolder} />
                        &nbsp;{e[0]}
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      </Container>
    </>
  );
}
