import { Button, Container, Table } from "react-bootstrap";
import { useAuth } from "../../firebaseContext";
import { useNavigate } from "react-router-dom";
export default function Shifts() {
  const navigate = useNavigate();
  const { admin, user } = useAuth();
  return (
    <>
      <Container fluid className="d-flex flex-column p-2 m-1">
        <Container
          fluid
          className="p-2 m-1 d-flex justify-content-around text-center"
        >
          {admin ? (
            <>
              <Button onClick={() => navigate("/addShifts")} variant="red">
                Add staff shifts
              </Button>
              <Button onClick={() => navigate("/addClient")} variant="red">
                Add/update client
              </Button>
            </>
          ) : (
            <></>
          )}
        </Container>
        <Container fluid="lg" className="p-2 m -1">
          <h2 className="text-center">Your rostered shifts </h2>
          <Table fluid="lg" responsive striped bordered className="text-center">
            <tr>
              <th>Shift detail</th>
              <th>Action</th>
            </tr>
            <tbody className="m-1">
              <tr>
                <td>Fadi shift</td>
                <td>
                  <Button>Details</Button>
                </td>
              </tr>
              <tr>
                <td>Fadi shift 2</td>
                <td>
                  <Button>Details</Button>
                </td>
              </tr>
              <tr>
                <td>Fadi shift 3</td>
                <td>
                  <Button>Details</Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Container>
      </Container>
    </>
  );
}
