import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import FileView from "./Pages/FileView";
import DownloadsPage from "./Pages/DownloadsPage";
import { useAuth } from "../firebaseContext";
import CreateUser from "./AdminTools/CreateUser";
import GetLogs from "./AdminTools/GetLogs";
import Navbar from "./Pages/Navbar";
import ViewFiles from "./Pages/ViewFiles.js";
import Profile from "./Pages/Profile.js";
import AddFolder from "./AdminTools/AddFolder";
import ManageUsers from "./AdminTools/ManageUsers";
import CreateUserFromLink from "./Pages/CreateUserFromLink";
import UserProfile from "./AdminTools/UserProfile";
import Updates from "./AdminTools/Updates";
import SideBar from "./Pages/SideBar";
import Folder from "./Pages/Folder";
import File from "./Pages/File";
import Index from "./Pages/Index";
import ResetPassword from "./Pages/ResetPassword";
import Shifts from "./Pages/Shifts";
import AddShifts from "./AdminTools/AddShifts";
import AddClient from "./AdminTools/AddClient";
import Training from "./AdminTools/Training.js";
import Questionnaire from "./AdminTools/Questionnaire.js";

function App() {
  const { currentUser, admin } = useAuth();
  if (currentUser) {
    return (
      <Router>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/fileView" element={<FileView />} />
          <Route path="/downloads" element={<DownloadsPage />} />
          <Route path="/folder">
            <Route path="/folder/:fullPath" element={<Folder />} />
          </Route>
          <Route path="/file">
            <Route path="/file/:fullPath" element={<File />} />
          </Route>
          <Route path="/viewFiles" element={<ViewFiles />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/sidebar" element={<SideBar />} />
          <Route path="/index" element={<Index />} />
          <Route path="/shifts" element={<Shifts />} />
          {admin ? (
            <>
              <Route path="/getLogs" element={<GetLogs />} />
              <Route path="/createUser" element={<CreateUser />} />
              <Route path="/addFolder" element={<AddFolder />} />
              <Route path="/manageUsers" element={<ManageUsers />} />
              <Route path="/user" element={<UserProfile />} />
              <Route path="/updates" element={<Updates />} />
              <Route path="/addShifts" element={<AddShifts />} />
              <Route path="/addClient" element={<AddClient />} />
              <Route path="/training" element={<Training />} />
              <Route path="/questionnaire" element={<Questionnaire />} />
            </>
          ) : (
            ""
          )}
        </Routes>
      </Router>
    );
  } else {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/createUserFromLink" element={<CreateUserFromLink />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
