import React, { useState } from "react";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Container,
  Form,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";

export default function Questionnaire() {
  const [answers, setanswers] = useState([]);
  const [answer1, setanswer1] = useState("");
  const [answer2, setanswer2] = useState("");
  const [answer3, setanswer3] = useState("");
  const [error, seterror] = useState("");
  const [alert, setalert] = useState("");
  function add(e) {
    e.preventDefault();
    if (e.target.name == "option1") {
      setanswer1(e.target.innerHTML);
    } else if (e.target.name == "option2") {
      setanswer2(e.target.innerHTML);
    } else if (e.target.name == "option3") {
      setanswer3(e.target.innerHTML);
    } else {
      seterror("There was an error. ");
    }
  }
  function reset(e) {
    e.preventDefault();
    setanswer1("");
    setanswer2("");
    setanswer3("");
  }
  function submit(e) {
    e.preventDefault();
    setanswers([answer1, answer2, answer3]);
    setalert("Your response has been submitted. Please wait for administrator");
    // console.log(answers);
  }
  return (
    <Container className="p-2">
      <Form>
        {alert ? (
          <>
            <Alert variant="teal">{alert}</Alert>
            <ButtonGroup className="w-100">
              <Button className="m-1" href="/">
                Go back
              </Button>
            </ButtonGroup>
          </>
        ) : (
          <>
            {answer1 ? (
              <>
                <Alert variant="red">Selected option 1 : {answer1}</Alert>
              </>
            ) : (
              <>
                <Form.Group className="p-2">
                  <Alert className="my-1">
                    <Badge>Question&nbsp;1&nbsp;:</Badge> Example question 1
                  </Alert>
                  <ListGroup>
                    <ListGroup.Item action onClick={add} name="option1">
                      option 1
                    </ListGroup.Item>
                    <ListGroup.Item action onClick={add} name="option1">
                      option 2
                    </ListGroup.Item>
                    <ListGroup.Item action onClick={add} name="option1">
                      option 3
                    </ListGroup.Item>
                    <ListGroup.Item action onClick={add} name="option1">
                      option 4
                    </ListGroup.Item>
                  </ListGroup>
                </Form.Group>
              </>
            )}
            {answer2 ? (
              <>
                <Alert variant="red">Selected option 2 : {answer2}</Alert>
              </>
            ) : (
              <>
                <Form.Group className="p-2">
                  <Alert className="my-1">
                    <Badge>Question&nbsp;2&nbsp;:</Badge> Example question 2
                  </Alert>
                  <ListGroup>
                    <ListGroup.Item action onClick={add} name="option2">
                      option 1
                    </ListGroup.Item>
                    <ListGroup.Item action onClick={add} name="option2">
                      option 2
                    </ListGroup.Item>
                    <ListGroup.Item action onClick={add} name="option2">
                      option 3
                    </ListGroup.Item>
                    <ListGroup.Item action onClick={add} name="option2">
                      option 4
                    </ListGroup.Item>
                  </ListGroup>
                </Form.Group>
              </>
            )}
            {answer3 ? (
              <>
                <Alert variant="red">Selected option 3: {answer3}</Alert>
              </>
            ) : (
              <>
                <Form.Group className="p-2">
                  <Alert className="my-1">
                    <Badge>Question&nbsp;3&nbsp;:</Badge> Example question 3
                  </Alert>
                  <ListGroup>
                    <ListGroup.Item action onClick={add} name="option3">
                      option 1
                    </ListGroup.Item>
                    <ListGroup.Item action onClick={add} name="option3">
                      option 2
                    </ListGroup.Item>
                    <ListGroup.Item action onClick={add} name="option3">
                      option 3
                    </ListGroup.Item>
                    <ListGroup.Item action onClick={add} name="option3">
                      option 4
                    </ListGroup.Item>
                  </ListGroup>
                </Form.Group>
              </>
            )}
            {answer1 && answer2 && answer3 ? (
              <>
                <ButtonGroup className="p-2 d-flex">
                  <Button className="m-1" onClick={submit}>
                    Submit
                  </Button>
                  <Button className="m-1" onClick={reset}>
                    Reset
                  </Button>
                </ButtonGroup>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </Form>
    </Container>
  );
}
