import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  FloatingLabel,
  Form,
} from "react-bootstrap";
import { useAuth } from "../../firebaseContext";

export default function AddShifts() {
  var currentDate = new Date().getDate();
  var currentMonth = new Date().getMonth();
  var currentYear = new Date().getFullYear();
  var currentDay = new Date().toISOString().substring(0, 10);
  var currentHour = new Date().getHours();
  var currentMinutes = new Date().getMinutes();
  var futureTimestamp = new Date().setHours(new Date().getHours() + 3);
  var futureHours = new Date(futureTimestamp).getHours();
  if (currentMinutes < 10) {
    currentMinutes = "0" + currentMinutes;
  }
  const currentTime = currentHour + ":" + currentMinutes;
  const FutureDate = futureHours + ":" + currentMinutes;

  const { getUsersCollection } = useAuth();
  const [view, setView] = useState(false);
  const clientName = useRef();
  const staffName = useRef();
  const startTime = useRef();
  const endTime = useRef();
  const shiftDate = useRef();
  const shiftDetails = useRef();
  const shiftNotes = useRef();
  // console.log(FutureDate);

  var staffNames = [];
  useEffect(async () => {
    const userdata = await getUsersCollection();
    // console.log(userdata); //works
    userdata.forEach((user) => {
      // console.log(user[2]); Display names
      staffNames.push(user);
    });
    const targetStaff = document.getElementById("staffCollection");
    staffNames.forEach((staff) => {
      targetStaff.options.add(new Option(staff[2] + " (" + staff[0] + ")"));
    });
  }, []);

  function handleShiftData(e) {
    e.preventDefault();
  }

  return (
    <>
      <Container fluid="lg" className="p-2">
        <Container className="text-center">
          <h2>Add shift details (beta)</h2>
        </Container>
        <Container>
          <Form onSubmit={handleShiftData}>
            <Form.Group className="my-2">
              <FloatingLabel label="Client name">
                <Form.Select
                  id="clientCollection"
                  placeholder="Select client from here"
                  required
                  ref={staffName}
                >
                  {console.log(staffNames)}
                  {staffNames.map((e) => {
                    return (
                      <>
                        <option>{e[2]}</option>
                      </>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
              <FloatingLabel label="Staff worker">
                <Form.Select
                  id="staffCollection"
                  placeholder="Select staff from here"
                  required
                  ref={clientName}
                ></Form.Select>
              </FloatingLabel>
              <Form.Group className="d-flex my-2">
                <FloatingLabel className="w-50" label="Start time">
                  <Form.Control
                    ref={startTime}
                    type="time"
                    value={currentDate}
                    required
                  />
                </FloatingLabel>
                <FloatingLabel className="w-50" label="End time">
                  <Form.Control
                    ref={endTime}
                    type="time"
                    value={FutureDate}
                    required
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="d-flex my-2">
                <FloatingLabel className="w-100" label="Shift date">
                  <Form.Control
                    type="date"
                    ref={shiftDate}
                    defaultValue={"04-10-2023"}
                    required
                  />
                </FloatingLabel>
              </Form.Group>
              <FloatingLabel className="w-50" label="Shift date">
                <Form.Control
                  type="date"
                  defaultValue={currentDay}
                  min={currentDay}
                />
              </FloatingLabel>
              <FloatingLabel label="Shift details">
                <Form.Control
                  as={"textarea"}
                  placeholder="Write shift details here"
                  style={{ height: "20vh" }}
                  required
                  ref={shiftDetails}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group className="my-2">
              <FloatingLabel label="Shift notes/comments">
                <Form.Control
                  as={"textarea"}
                  placeholder="Write shift notes here"
                  style={{ height: "15vh" }}
                  required
                  ref={shiftNotes}
                />
              </FloatingLabel>
            </Form.Group>
            <ButtonGroup className="d-flex my-3">
              <Button variant="secondary" className="p-2 mr-auto" type="submit">
                Publish and save shift
              </Button>
              <Button variant="danger" className="p-2" type="reset">
                Reset
              </Button>
            </ButtonGroup>
          </Form>
        </Container>
      </Container>
    </>
  );
}
