import React from "react";
import ReactDOM from "react-dom";
import App from "./Components/App";
import "./styles/App.scss";
import { AuthProvider } from "./firebaseContext";

ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  document.getElementById("root")
);
