import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, ButtonGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../firebaseContext";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      // console.log(emailRef.current.value);
      navigate("/");
    } catch (err) {
      // console.log(err.message);
      setError(err.message);
    }
    setLoading(false);
  }

  return (
    <>
      <div className="container-sm py-2">
        <Card>
          <Card.Body>


            <h2 className="text-center my-2">
              Welcome to Nobility Care Australia
            </h2>
            <h4 className="text-center my-2">
              Please log in with your details
            </h4>


            {error && <Alert variant="danger">{error}</Alert>}

            
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mt-3" id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  ref={emailRef}
                  required
                />
              </Form.Group>


              <Form.Group className="mt-3" id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  ref={passwordRef}
                  required
                />
              </Form.Group>


              <ButtonGroup className="d-flex flex-wrap mt-3">

                <Button disabled={loading} type="submit">
                  Log in
                </Button>

                <Button variant="danger" type="reset">
                  Reset
                </Button>

              </ButtonGroup>


              <h6 className="text-center mt-4">
                Contact the administrator for log in details
              </h6>


            </Form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
