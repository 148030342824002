import React, { useRef, useState } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Container,
  ButtonGroup,
  ProgressBar,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../firebaseContext";

export default function CreateUser() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { SendPasswordLink, signUp } = useAuth();
  const [error, setError] = useState("");
  const [alert, setalert] = useState("");
  const [loading, setLoading] = useState(false);
  const [multiple, setmultiple] = useState(false);
  const [progressBar, setprogressBar] = useState(false);
  // const [emailNumber, setemailNumber] = useState(0);
  const [emails, setemails] = useState([]);
  const [progress, setprogress] = useState(0);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    setError("");

    try {
      if (multiple) {
        setLoading(true);
        emails.forEach(async (email) => {
          // console.log(email); // works
          await SendPasswordLink(email);
        });
        setalert(
          "Sign in links to " + emails.length + " emails have been sent!"
        );
        setLoading(false);
      } else {
        setLoading(true);
        await SendPasswordLink(emailRef.current.value);
        setLoading(false);
        setalert("Email sent!");
        setLoading(false);
      }
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  }

  function handleAddField(e) {
    e.preventDefault();
    setmultiple(true);
    const currentEmail = emailRef.current.value;
    setemails((emails) => [...emails, currentEmail]);
    emailRef.current.value = "";
    console.log(emails);
  }
  function handleCancel(e) {
    e.preventDefault();
    setmultiple(false);
    setemails([]);
  }

  return (
    <>
      <Container fluid="lg" className="d-flex flex-column">
        {/* <NotAvailable /> */}

        <Card className="p-2">
          <h2 className="text-center mb-4">Create new user</h2>
          {error || alert ? (
            <>
              <Alert variant={error ? "danger" : "info"}>
                {error || alert}
              </Alert>
            </>
          ) : (
            <>
              <Alert variant="secondary">
                Ask the user to check their spam/junk folder. The email comes
                from noreply@portal.nobilitycare.com.au
              </Alert>
            </>
          )}

          {error ? <Alert variant="danger">{error}</Alert> : <></>}

          <Container fluid className="d-flex flex-wrap">
            {emails.map((email) => {
              return (
                <p className="bg-blue text-white align-content-center align-items-center p-2 m-1">
                  {email}
                </p>
              );
            })}
          </Container>
          <Form
            onSubmit={handleSubmit}
            className="d-flex flex-column justify-content-center"
          >
            <Form.Group className="d-flex flex-column mt-3" id="email">
              <Form.Label>{multiple ? <>Next email</> : <>Email</>}</Form.Label>
              <Form.Control
                name="email"
                type="email"
                ref={emailRef}
                required={!multiple}
              />
              <Button className="m-2" onClick={handleAddField}>
                Add&nbsp;another&nbsp;email?
              </Button>
            </Form.Group>

            <ButtonGroup className="mt-3 w-100">
              <Button
                variant="info"
                disabled={loading}
                className="w-50 text-white m-1"
                type="submit"
              >
                {multiple ? <>Create&nbsp;users</> : <>Create&nbsp;user</>}
              </Button>

              <Button
                onClick={handleCancel}
                variant="secondary"
                className="w-50 m-1"
                type="reset"
              >
                Reset
              </Button>
            </ButtonGroup>
            {progressBar ? (
              <>
                <Container fluid className="d-flex p-2">
                  <ProgressBar striped variant="teal" now={progress} />
                  {progress === 100 ? (
                    <>
                      <Button onClick={() => navigate(-1)}>Go back</Button>
                    </>
                  ) : (
                    <></>
                  )}
                </Container>
              </>
            ) : (
              <></>
            )}
          </Form>
        </Card>
      </Container>
      ;
    </>
  );
}
