import React, { useEffect, useState } from "react";
import { Container, Alert } from "react-bootstrap";
import UpdateLog from "../../updates.md";

export default function Updates() {
  const [Data, setData] = useState([]);
  const [show, setshow] = useState(false);

  useEffect(async () => {
    // console.log(UpdateLog);
    fetch(UpdateLog)
      .then((r) => r.text())
      .then((text) => {
        setData(text);
        setshow(true);
      });
  }, []);

  return (
    <Container>
      {show ? (
        <>
          <Container fluid className="mt-5">
            <Alert>Update notes</Alert>
            <textarea
              readOnly
              style={{ width: "100%", height: "70vh", resize: "none" }}
            >
              {Data}
            </textarea>
          </Container>
        </>
      ) : (
        <>
          <Container>
            <p>Done</p>
          </Container>
        </>
      )}
    </Container>
  );
}
