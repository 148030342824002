import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, uploadBytes } from "firebase/storage";
import {
  collection,
  getFirestore,
  setDoc,
  doc,
  Timestamp,
  getDocs,
  orderBy,
  query,
  where,
  limit,
} from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBnEdCwZCEh_eG-TnHLiVxfwiHZNG_GuSU",
  authDomain: "ncafiles-6e036.firebaseapp.com",
  projectId: "ncafiles-6e036",
  storageBucket: "ncafiles-6e036.appspot.com",
  messagingSenderId: "760276261464",
  appId: "1:760276261464:web:24c12a7163c8a0ddda882d",
  measurementId: "G-FD7HDJKJEN",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);

// Firestore functions

const logsCollections = collection(db, "logs");
const indexCollection = collection(db, "index");

export function addLog(userEmail, fileName, activity) {
  return setDoc(doc(logsCollections), {
    Filename: fileName,
    Email: userEmail,
    Time: Timestamp.fromDate(new Date()),
    Activity: activity,
  });
}

export async function getLogsByEmail(email) {
  const q = query(logsCollections, where("Email", "==", email));
  const data = await getDocs(q);
  var exports = [];
  data.forEach((doc) => {
    exports.push([
      doc.data().Filename,
      doc.data().Email,
      new Date(doc.data().Time.toDate()).toString(),
      doc.data().Activity,
    ]);
  });
  return exports;
}
export async function getLogsByFilename(filename) {
  const q = query(logsCollections, where("Filename", "==", filename));
  const data = await getDocs(q);
  var exports = [];
  data.forEach((doc) => {
    exports.push([
      doc.data().Filename,
      doc.data().Email,
      new Date(doc.data().Time.toDate()).toString(),
      doc.data().Activity,
    ]);
  });
  return exports;
}

export async function getLogs() {
  const q = query(logsCollections, orderBy("Time", "desc"), limit(25));
  const data = await getDocs(q);
  var exports = [];
  data.forEach((doc) => {
    exports.push([
      doc.data().Filename,
      doc.data().Email,
      // new Date(doc.data().Time.seconds + doc.data().Time.nanoseconds).toLocaleString()
      new Date(doc.data().Time.toDate()).toString(),
      doc.data().Activity,
    ]);
  });
  return exports;
}

export async function getFilesIndex() {
  const q = query(indexCollection);
  const data = await getDocs(q);
  return data;
}
export async function uploadFilesTask(ref, file) {
  // console.log(ref);
  await setDoc(doc(indexCollection), {
    filename: ref.name,
    location: ref.parent.fullPath,
  });
  return uploadBytes(ref, file);
}
export async function uploadImagesFolder(ref) {
  await setDoc(doc(indexCollection), {
    filename: ref.name,
    location: ref.fullPath,
  });
}
export async function uploadImagesTask(ref, file) {
  // console.log(ref);
  return uploadBytes(ref, file);
}
export async function AddShift() {
  // to do
  return "";
}
export async function getAllShifts(user) {
  // to do
  return "";
}
export async function clockIn() {
  
}
export async function CLockOut() {
  
}

//.................................

export default app;
