import React from "react";
import { Container, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faFileArrowDown, faIndent,faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function SubNav() {
  const navigate = useNavigate();

  return (
    <Container
      fluid="lg"
      className="py-4 px-2 justify-content-center text-center border"
    >
      <h2>Links</h2>
      <Container className="d-flex flex-wrap justify-content-center py-2">
        <Button
          onClick={() => navigate("/viewFiles")}
          className="m-2 text-white"
          variant="teal"
        >
          <FontAwesomeIcon icon={faClipboard} /> &nbsp;
          Policies,&nbsp;Procedures&nbsp;&amp;&nbsp;Guidelines&nbsp;
        </Button>
        <Button
          onClick={() => navigate("/downloads")}
          className="m-2 text-white"
          variant="cyan"
        >
          <FontAwesomeIcon icon={faFileArrowDown} />{" "}
          &nbsp;Downloadable&nbsp;Forms&nbsp;
        </Button>
        <Button
          onClick={() => navigate("/index")}
          className="m-2 text-white"
          variant="blue"
        >
          <FontAwesomeIcon icon={faIndent} /> &nbsp;Files Index
        </Button>
      </Container>
    </Container>
  );
}
