import React from "react";
import { Container, Button, ButtonGroup, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faPlus,
  faUsers,
  faStickyNote,
  faScrewdriverWrench,
  faClipboardList,
  faBook,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function AdminTools() {
  const navigate = useNavigate();
  return (
    <>
      <h3 className="py-2 mt-1 text-center">Admin actions </h3>
      <Container
        fluid
        className="d-flex flex-wrap py-3 px-2 border justify-content-center"
      >
        <ButtonGroup className="w-100 d-flex p-1 flex-wrap">
          <Button
            onClick={() => navigate("/createUser")}
            className="m-1"
            variant="secondary"
          >
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;Create new user
          </Button>

          <Button
            onClick={() => navigate("/getLogs")}
            className="m-1"
            variant="indigo"
          >
            <FontAwesomeIcon icon={faPaperPlane} />
            &nbsp;Read logs
          </Button>
          <Button
            onClick={() => navigate("/manageUsers")}
            className="m-1 text-white"
            variant="pink"
          >
            <FontAwesomeIcon icon={faUsers} />
            &nbsp;Users
          </Button>

          <Button
            onClick={() => navigate("/updates")}
            className="m-1 text-white"
            variant="orange"
          >
            <FontAwesomeIcon icon={faStickyNote} />
            &nbsp;Website updates
          </Button>
          <Button
            href="https://console.firebase.google.com/u/0/project/ncafiles-6e036/overview"
            target="_blank"
            className="m-1"
            variant="danger"
          >
            <FontAwesomeIcon icon={faScrewdriverWrench} />
            &nbsp;Firebase console
          </Button>
        </ButtonGroup>
        <Container className="p-2">
          <Alert className="text-center w-100 p-2" variant="pink">
            Under Development
          </Alert>
          <ButtonGroup className="w-100 d-flex">
            <Button href="/training" className="m-1 text-white" variant="cyan">
              <FontAwesomeIcon icon={faBook} />
              &nbsp;Training
            </Button>
            <Button href="/addShifts" className="m-1" variant="red">
              <FontAwesomeIcon icon={faClipboardList} />
              &nbsp; Add&nbsp;staff&nbsp;shifts
            </Button>
            <Button
              onClick={() => navigate("/shifts")}
              className="m-1 text-white"
              variant="pink"
            >
              <FontAwesomeIcon icon={faBriefcase} /> &nbsp;Shifts
            </Button>
          </ButtonGroup>
        </Container>
      </Container>
    </>
  );
}
