import React from "react";
import { Button, NavDropdown } from "react-bootstrap";

export default function QuickLinkItem({ link, itemname }) {
  return (
    <>
      <NavDropdown.Item
        id="quicklinks"
        as={Button}
        href={link}
        target="_blank"
        className="p-2 mb-1"
      >
        {itemname}
      </NavDropdown.Item>
    </>
  );
}
