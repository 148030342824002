import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Button,
  Table, Modal,
  Form,
  ButtonGroup
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faList, faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { getLogs, getLogsByEmail, getLogsByFilename } from "../../firebase";

export default function GetLogs() {
  const [result, setresult] = useState(null);
  const [tableView, settableView] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalFile, setModalFile] = useState(false);
  const emailRef = useRef();
  const fileRef = useRef();

  function handleEmails(e) {
    e.preventDefault();
    // console.log(emailRef.current.value);
    setModalShow(false);
    getLogsByEmail(emailRef.current.value).then((r) => {
      setresult(r);
      //   console.log(result);
    });
    settableView(true);
  }
  function handleFiles(e) {
    e.preventDefault();
    // console.log(emailRef.current.value);
    setModalFile(false);
    getLogsByFilename(fileRef.current.value).then((r) => {
      setresult(r);
      //   console.log(result);
    });
    settableView(true);
  }

  function handleLogs(e) {
    e.preventDefault();
    getLogs().then((r) => {
      setresult(r);
      //   console.log(result);
    });
    settableView(true);
  }


  function Model(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Sort logs by email
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                required
                ref={emailRef}
                name="email"
                type="email"
                placeholder="Enter email"
              />
            </Form.Group>
            <Modal.Footer>
              <Button onClick={handleEmails} variant="primary" type="submit">
                Submit
              </Button>
              <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
  function ModelFile(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Sort logs by filename
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                ref={fileRef}
                name="filename"
                type="text"
                placeholder="Enter Filename"
                required
              />
            </Form.Group>
            <Modal.Footer>
              <Button onClick={handleFiles} variant="primary" type="submit">
                Submit
              </Button>
              <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  useEffect(() => {
    getLogs().then((r) => {
      setresult(r);
    });
  }, []);

  return (
    <>
      <Model show={modalShow} onHide={() => setModalShow(false)} />
      <ModelFile show={modalFile} onHide={() => setModalFile(false)} />
      <Container fluid className="py-3">
        <h3 className="mb-3">Get logs by </h3>
        <ButtonGroup className="d-flex flex-wrap p-2">
          <Button
            onClick={handleLogs}
            className="m-1"
            variant="outline-primary"
          >
            <FontAwesomeIcon icon={faList} />
            &nbsp;All&nbsp;(Time&nbsp;-&nbsp;descending)
          </Button>
          <Button
            onClick={() => setModalShow(true)}
            className="m-1"
            variant="outline-primary"
          >
            <FontAwesomeIcon icon={faMailBulk} />
            &nbsp;Email
          </Button>
          <Button
            onClick={() => setModalFile(true)}
            className="m-1"
            variant="outline-primary"
          >
            <FontAwesomeIcon icon={faFile} />
            &nbsp;Filename
          </Button>
        </ButtonGroup>

        {tableView ? (
          <>
            <Table
              id="table"
              striped
              bordered
              size="sm"
              responsive="sm"
              className=" p-2 my-2"
            >
              <thead>
                <tr>
                  <td>Filename</td>
                  <td>Email</td>
                  <td>Time</td>
                  <td>Activity</td>
                </tr>
              </thead>
              <tbody>
                {result.map((e) => {
                  return (
                    <>
                      <tr>
                        <td>{e[0]}</td>
                        <td>{e[1]}</td>
                        <td>{e[2]}</td>
                        <td>{e[3]}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </>
        ) : (
          ""
        )}
      </Container>
    </>
  );
}
