import React, { useState } from "react";
import { Navbar, Container, Button, Nav, Alert } from "react-bootstrap";
import { useAuth } from "../../firebaseContext";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faLaptop,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import logo2 from "../../Images/nca-logo-landscape-neg-clean-v3-1.png";
import QuickLinks from "./QuickLinks";

export default function NavbarComponent() {
  const [error, setError] = useState(null);
  const { logout } = useAuth();
  const navigate = useNavigate();

  async function handleLogout() {
    setError("");

    try {
      await logout();
      navigate("/");
    } catch {
      setError("Failed to log out");
    }
  }
  return (
    <>
      <Navbar
        bg="dark"
        variant="dark"
        collapseOnSelect
        expand="md"
        sticky="top"
      >
        <Container fluid className=" p-1 d-flex justify-content-between">
          <Navbar.Brand href="/" className="p-2 w-50 mx-3">
            <img src={logo2} height={"50"} />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="nav" />
          <Navbar.Collapse id="nav" className="justify-content-end ">
            <Nav className="d-flex p-1 justify-content-between align-items-md-center">
              <QuickLinks />
              <Button
                variant="orange"
                className="m-1 p-2 text-white"
                href="/profile"
              >
                <FontAwesomeIcon icon={faLaptop} />
                &nbsp;Profile
              </Button>
              <Button
                variant="pink"
                className="m-1 p-2 text-white"
                href="/notifications"
              >
                <FontAwesomeIcon icon={faBell} />
                &nbsp;Notifications
              </Button>
              <Button
                variant="danger"
                className="m-1 p-2"
                onClick={handleLogout}
              >
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
                &nbsp;Log Out
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {error ? (
        <>
          <Alert variant="danger">{error}</Alert>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
