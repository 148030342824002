import React from "react";
import SubNav from "./SubNav";
import AdminTools from "../AdminTools/AdminTools";
import { Container } from "react-bootstrap";
import { useAuth } from "../../firebaseContext";
import Footer from "./Footer";

export default function Dashboard() {
  const { admin } = useAuth();

  document.oncontextmenu = function (e) {
    e.preventDefault();
  };
  return (
    <>
      <Container id="dashboard" fluid="lg" className="p-1 d-flex flex-column">
        {admin ? <AdminTools /> : ""}
        <SubNav />
      </Container>
      <Footer />
    </>
  );
}
