import React, { useContext, useState, useEffect } from "react";
import { auth, db, storage } from "./firebase";
import {
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  browserSessionPersistence,
  setPersistence,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendEmailVerification,
  updateProfile,
  confirmPasswordReset,
} from "firebase/auth";
import {
  collection,
  setDoc,
  doc,
  getDocs,
  getDoc,
  query,
} from "firebase/firestore";
import { ref, listAll } from "firebase/storage";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [admin, setadmin] = useState(false);
  const [loading, setLoading] = useState(true);

  async function getUpdateNotes() {
    const notesRef = ref(storage, "updateNotes");
    const list = await listAll(notesRef);
    // console.log(exports.length);
    return list;
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function SendPasswordLink(email) {
    console.log(email);
    const actionCodeSettings = {
      url: "https://portal.nobilitycare.com.au/createUserFromLink",
      // This must be true.
      handleCodeInApp: true,
    };

    return sendSignInLinkToEmail(auth, email, actionCodeSettings);
  }

  function checkLoginLink(link) {
    return isSignInWithEmailLink(auth, link);
  }
  function loginFromEmailLink(email, link) {
    return signInWithEmailLink(auth, email, link);
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    const actionCodeSettings = {
      url: "https://portal.nobilitycare.com.au/",
      // This must be true.
      handleCodeInApp: true,
    };

    return sendPasswordResetEmail(auth, email, actionCodeSettings);
  }
  function confirmPasswordLink(code, email) {
    return confirmPasswordReset(auth, code, email);
  }
  function sendVerifyEmail(user) {
    return sendEmailVerification(user);
  }
  function updateUser(displayName) {
    return updateProfile(currentUser, {
      displayName: displayName,
    });
  }

  //firestore functions

  const usersCollections = collection(db, "users");
  const shiftsCollection = collection(db, "shifts");

  function addUserData(user) {
    const userRef = doc(usersCollections, user.email);
    return setDoc(userRef, {
      Email: user.email,
      UID: user.uid,
      Display: user.displayName,
      PhotoURL: user.photoURL,
      Verified: user.emailVerified,
    });
  }
  async function getUsersCollection() {
    const q = query(usersCollections);
    const data = await getDocs(q);
    var exports = [];
    data.forEach((doc) => {
      exports.push([
        doc.data().Email,
        doc.data().UID,
        doc.data().Display,
        doc.data().PhotoURL,
        doc.data().Verified,
      ]);
    });
    return exports;
  }
  async function getUserData(email) {
    const userRef = doc(usersCollections, email);
    const data = await getDoc(userRef);
    return data;
  }
  async function addNewCLient(name, location, homeAddress, cmergencyContact) {
    
  }
  async function updateClient() {
    
  }
  async function getAllClients() {
    
  }
  async function getAllStaff() {

    
  }
  async function addShiftAll(client, staff, start, end, date, details, comments) {


    
  }
  async function addShiftStaff() {
    
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      user ? addUserData(user) : <></>;
      if (
        user?.uid === "I84gKOqld8YYtDh66o9lRmHFOA02" ||
        user?.email === "admin@nobilitycare.com.au"
      ) {
        setadmin(true);
      } else if (user && user.email === "tanveer.singh@nobilitycare.com.au") {
        setadmin(true);
      }
      // console.log(admin);
      setPersistence(auth, browserSessionPersistence);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    admin,
    signUp,
    login,
    logout,
    resetPassword,
    SendPasswordLink,
    confirmPasswordLink,
    checkLoginLink,
    loginFromEmailLink,
    getUsersCollection,
    getUserData,
    sendVerifyEmail,
    updateUser,
    getUpdateNotes,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
