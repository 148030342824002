import React from "react";
import { useLocation } from "react-router-dom";
import { Container, Card } from "react-bootstrap";
import SubNav from "./SubNav";

export default function FileView({URL}) {
  const location = useLocation();
  // console.log(location);

  return (
    <>
      <Container id="fileView" fluid className="mt-5 text-center">
        <SubNav />
        <Container>
          <Card className="d-flex justify-content-center align-items-center">
            {URL ? (
              <img src={URL} alt="" width="80%" height="auto" />
            ) : (
              <h3>
                There is a problem with the file link. Please contact the
                administrator.
              </h3>
            )}
          </Card>
        </Container>
      </Container>
    </>
  );
}
