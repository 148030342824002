import React, { useState } from "react";
import {
  Button,
  Container, ButtonGroup,
  Collapse,
  Nav
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function () {
  const navigate = useNavigate();
  const [ButtonStatus, setButtonStatus] = useState("Click to Close");
  const [show, setshow] = useState(true);

  function handleButton(e) {
    e.preventDefault();
    if (ButtonStatus === ">|") {
      document.getElementById("links").style.display = "block";
      setButtonStatus("Click to Close");
      setshow(true);
    } else if (ButtonStatus === "Click to Close") {
      document.getElementById("links").style.display = "none";
      setButtonStatus(">|");
      setshow(false);
    }
  }
  return (
    <Nav id="sidebar" className="border p-1 w-25">
      <Container
        fluid
        className="p-2 d-flex justify-content-between align-items-center"
      >
        <Button
          variant={ButtonStatus === "Click to Close" ? "secondary" : "primary"}
          className={
            ButtonStatus == "Click to Close" ? "py-2 mx-2 w-100" : "py-2 mx-2"
          }
          onClick={handleButton}
        >
          {ButtonStatus}
        </Button>
      </Container>
      <Collapse in={show} dimension={"height"}>
        <Container id="links" className="p-2 text-center">
          <ButtonGroup className="d-flex flex-column">
            <Nav.Link
              onClick={() => navigate("/downloads")}
              variant="dark"
              className="p-2 mb-2"
            >
              Policies,&nbsp;Procedures&nbsp;&amp;&nbsp;Guidelines&nbsp;
            </Nav.Link>
          </ButtonGroup>
          <ButtonGroup className="d-flex flex-column">
            <Nav.Link
              onClick={() => navigate("/downloads")}
              variant="dark"
              className="p-2 mb-2"
            >
              Downloadable&nbsp;Forms&nbsp;
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/downloads")}
              variant="outline-dark"
              className="p-2 mb-2"
            >
              Human&nbsp;Resources
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/downloads")}
              variant="outline-dark"
              className="p-2 mb-2"
            >
              Partipant&nbsp;services
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/downloads")}
              variant="outline-dark"
              className="p-2 mb-2"
            >
              Esay&nbsp;read&nbsp;documents
            </Nav.Link>
          </ButtonGroup>
        </Container>
      </Collapse>
    </Nav>
  );
}
