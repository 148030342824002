import React, { useState } from "react";
import {
  Accordion,
  Alert,
  Button,
  Card,
  Carousel,
  Container,
  Image,
  ListGroup,
  ListGroupItem,
  ProgressBar,
  Tab,
  Table,
  Tabs,
  Toast,
} from "react-bootstrap";
import SDLC from "../Images/SDLC.png";
import DDP1 from "../Images/DDP Xmas 2023.png";
import DDP2 from "../Images/DDP S2E1.png";
import DDP3 from "../Images/DDP S2E4.png";
import Alt from "../Images/all-alhaitham-stickers-from-paimons-paintings-v0-kr6ytyzkh4da1.png";

export default function TrainingModule() {
  const [progress, setprogress] = useState(0);
  const [progressVariant, setprogressVariant] = useState("red");

  function fillBar(number, variant) {
    console.log("working...");
    setprogress(number);
    setprogressVariant(variant);
  }
  return (
    <Container>
      <Container className="p-2 my-1">
        <Alert className="text-center">
          Elements of E-training module components
        </Alert>
        <ProgressBar
          variant={progressVariant}
          now={progress}
          label={progress + "%"}
          animated
        />
      </Container>
      <Accordion
        defaultActiveKey="0"
        onLoad={(e) => {
          fillBar(10);
        }}
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>Just text</Accordion.Header>
          <Accordion.Body>
            The project focused on the application management system which deals
            with the interest of the Australian people who are interested in the
            Cape and with the same initially the John chugli is the Managing
            Director and believe that the virtual sim classes suffer the same
            definitely be as working for the go virtual organisation for the
            last three month the mobile application development has been done
            and considering our project as the virtual Team Management so we
            have applied for the tender management and the important of
            importance of project and various mobile development have been our
            priority basically we worked(www.jenolancaves.org.au, n.d.) for the
            helping cave community of the Australia to have the cave system
            safety responsibility and to promote the effective exchange of
            information between the environment and Forest project has been kept
            into the mind for optimising both iPhone and Android devices it will
            also have the enrolment and payment fee abided by it.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item
          eventKey="1"
          onClick={(e) => {
            fillBar(20, "red");
          }}
        >
          <Accordion.Header>Text with pictures(s)</Accordion.Header>
          <Accordion.Body>
            The software development cycle is using for step by step software
            development. Initial planning is helpful for any development. First,
            initialise the entire application this phase is useful for the
            development cycle. The first phase of the software development cycle
            that is the planning phase. This phase is the first phase of SDLC,
            proper planning is done by the entire team which is help to planning
            out the of documents, and found of hardware (Systems Development
            Life Cycle, n.d.) and software requirements which is generally using
            for the future stages of SDLC life cycle.
            <Image src={SDLC} fluid></Image>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item
          eventKey="2"
          onClick={(e) => {
            fillBar(30, "orange");
          }}
        >
          <Accordion.Header>List</Accordion.Header>
          <Accordion.Body>
            <ListGroup>
              <ListGroupItem className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    Cohen, E. (2018). A Beginner-Friendly Guide to Work
                    Breakdown Structures (WBS). [online]
                  </div>
                  Workamajig.com. Available at:
                  https://www.workamajig.com/blog/guide-to-work-breakdown-structures-wbs.
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    Cost Estimation for Projects: How to Estimate Accurately
                    (2019).
                  </div>
                  ProjectManager.com. Available at:
                  https://www.projectmanager.com/blog/cost-estimation-for-projects.
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    Iastate.edu. (2016). Understanding Cash Flow Analysis.
                    [online]
                  </div>
                  Available at:
                  https://www.extension.iastate.edu/agdm/wholefarm/html/c3-14.html.
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    Murphy, C.B. (2019). Understanding the Cash Flow Statement.
                    [online]
                  </div>
                  Investopedia. Available at:
                  https://www.investopedia.com/investing/what-is-a-cash-flow-statement/.
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    Paymo. (2020). Critical Path in Project Management and How
                    to Add It to a Gantt Chart. [online]
                  </div>
                  Available at: https://www.paymoapp.com/blog/critical-path/.
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    Systems Development Life Cycle. (n.d.). Iterative SDLC
                    Model.[online]
                  </div>
                  Available at:
                  https://sdlc.ravingsoftware.com/iterative-sdlc-model/
                  [Accessed 19 Sep. 2020].
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div className="ms-2 me-auto">
                  <div className="fw-bold">
                    Teamgantt.com. (2018). How to Create a Project Management
                    Communication Plan | TeamGantt. [online]
                  </div>
                  Available at:
                  https://www.teamgantt.com/blog/project-management-communication-plan.
                </div>
              </ListGroupItem>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item
          eventKey="3"
          onClick={(e) => {
            fillBar(40, "teal");
          }}
        >
          <Accordion.Header>Table</Accordion.Header>
          <Accordion.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Stakeholder</th>
                  <th>Concerns</th>
                  <th>Quadrant</th>
                  <th>Strategy</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>APLLICATION DEVELOPER</td>
                  <td>Development and other aspects on time</td>
                  <td>Key Player</td>
                  <td>Recurrent communication and address imperative issue</td>
                </tr>
                <tr>
                  <td>Taylor and Shannon</td>
                  <td>Sponsors and capital investment </td>
                  <td>Keep Satisfied</td>
                  <td>
                    Communication requirement ensuring with them the capital
                    details
                  </td>
                </tr>
                <tr>
                  <td>Skye Tate</td>
                  <td>
                    requirements and other information which is required for the
                    development will be cleared and communicated by them
                  </td>
                  <td>Keep Informed</td>
                  <td>
                    Technical staff answer questions and address concerns and
                    provide test results for validation
                  </td>
                </tr>
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item
          eventKey="4"
          onClick={(e) => {
            fillBar(60, "cyan");
          }}
        >
          <Accordion.Header>Tabs</Accordion.Header>
          <Accordion.Body>
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="mb-3"
              fill
            >
              <Tab eventKey="home" title="Section 1">
                Research is an investigation and study of materials in order to
                establish facts and conclusions In universities, there is access
                to libraries and academic sources and those are a very reliable
                sources of information to put into the research in workplaces,
                there are a lot of opportunities to learn from more experiences
                work-mates as well as the direct practical knowledge. The skills
                that are essential for an effective research at workplace are
                communication skills, time management and problem solving just
                to name a few.
              </Tab>
              <Tab eventKey="profile" title="Section 2">
                Primary sources: Main text or work that you are discussing,
                actual data or research results, or historical documents. For
                instance: journals, speeches, interviews. Secondary sources: :
                Records generated by an event but written by non-participants in
                the event. Based on from primary sources but they have been
                interpreted or analysed. For instance, biographies, general
                histories.
              </Tab>
              <Tab eventKey="contact" title="Section 3 (disabled)" disabled>
                Every job requires human interaction as developing strong
                communication skills can help facilitate this interaction with
                others, enabling you to perform your own job more effectively
                and productively. Similarly, collaboration helps to share the
                vision and idea in between us. So, it helps us to perform our
                work more effectively and accurately.
              </Tab>
            </Tabs>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5" onClick={(e) => {
            fillBar(70, "forth");
          }}>
          <Accordion.Header>Carousel</Accordion.Header>
          <Accordion.Body>
            <Alert>
              A picture slideshow basically.{<br></br>} It cycles through slides
              every few seconds
            </Alert>
            <Carousel>
              <Carousel.Item>
                <Image src={DDP2} fluid text="Second slide" />
                <Carousel.Caption className="bg-primary bg-opacity-75">
                  <h3>First slide label</h3>
                  <p>
                    Nulla vitae elit libero, a pharetra augue mollis interdum.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <Image src={DDP1} fluid text="Second slide" />
                <Carousel.Caption className="bg-primary bg-opacity-75">
                  <h3>Second slide label</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <Image src={DDP3} fluid text="Second slide" />
                <Carousel.Caption className="bg-primary bg-opacity-75">
                  <h3>Third slide label</h3>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl
                    consectetur.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6"onClick={(e) => {
            fillBar(80, "teal");
          }}>
          <Accordion.Header>Cards</Accordion.Header>
          <Accordion.Body className="d-flex justify-content-md-evenly align-items-center flex-wrap flex-column flex-md-row">
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={Alt} />
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card>
            <Card style={{ width: "18rem" }}>
              <Card.Img variant="top" src={Alt} />
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroup.Item>Cras justo odio</ListGroup.Item>
                <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
              </ListGroup>
              <Card.Body>
                <Card.Link href="#">Card Link</Card.Link>
                <Card.Link href="#">Another Link</Card.Link>
              </Card.Body>
            </Card>
            <Card style={{ width: "18rem" }}>
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Card Subtitle
                </Card.Subtitle>
                <Card.Text>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <Card.Link href="#">Card Link</Card.Link>
                <Card.Link href="#">Another Link</Card.Link>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header as="h5">Featured</Card.Header>
              <Card.Body>
                <Card.Title>Special title treatment</Card.Title>
                <Card.Text>
                  With supporting text below as a natural lead-in to additional
                  content.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7"onClick={(e) => {
            fillBar(100, "green");
          }}>
          <Accordion.Header>Website colors</Accordion.Header>
          <Accordion.Body>
            {[
              "primary",
              "secondary",
              "success",
              "warning",
              "danger",
              "info",
              "dark",
              "indigo",
              "pink",
              "yellow",
              "purple",
              "orange",
              "teal",
              "cyan",
              "green",
              "red",
              "gray",
              "blue",
              "forth",
            ].map((v) => (
              <>
                <Alert variant={v}>This box is using {v} color</Alert>
              </>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="template">
          <Accordion.Header>Template(empty)</Accordion.Header>
          <Accordion.Body></Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}
