import React from "react";
import { Button, Container, Image } from "react-bootstrap";

export default function Footer() {
  return (
    <Container fluid className="bg-secondary text-center text-white p-2 m-1">
      <h4 className="p-3">Support</h4>
      <Container className="d-flex flex-wrap justify-content-center">
        <Button
          variant="red"
          className="m-1 p-2"
          href={"https://www.nobilitycare.com.au/feedback"}
          target="_blank"
        >
          Feedback
        </Button>
        <Button
          variant="red"
          className="m-1 p-2"
          href={"https://www.nobilitycare.com.au/privacy-policy"}
          target="_blank"
        >
          Privacy Policy
        </Button>
        <Button
          variant="red"
          className="m-1 p-2"
          href={"https://www.nobilitycare.com.au/terms-conditions"}
          target="_blank"
        >
          Terms & Conditions
        </Button>
      </Container>
      <Container className="d-flex flex-column p-2 justify-content-center align-items-center">
        <p className="p-2">
          In the spirit of reconciliation, Nobility Care Australia acknowledges
          the Traditional Custodians of country throughout Australia and their
          connections to land, sea and community. We pay our respect to their
          Elders past and present and extend that respect to all Aboriginal and
          Torres Strait Islander peoples today.
        </p>
        <Image
          className="p-2"
          width={"100px"}
          fluid
          src="https://www.nobilitycare.com.au/images/logo/nca-logo-icon-neg-clean.svg"
        />
        <p className="p-1">Registered NDIS Provider</p>
        <p className="p-1">© Copyright 2023 NOBILITY CARE AUSTRALIA PTY LTD</p>
        <p className="p-1">ABN: 71 642 289 601 | ACN: 642 289 601</p>
      </Container>
    </Container>
  );
}
