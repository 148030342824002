import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Container,
  Card,
  Alert,
  Form,
  ButtonGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../firebaseContext";

export default function Profile() {
  const { currentUser, resetPassword, getUserData, updateUser } = useAuth();
  const [alert, setalert] = useState(null);
  const [MainAlert, setMainAlert] = useState(null);
  const [Emailalert, setEmailalert] = useState(null);
  const [verifyMail, setverifyMail] = useState(null);
  const [reset, setreset] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [show, setshow] = useState(false);
  const [Data, setData] = useState(null);

  const navigate = useNavigate();

  const displayName = useRef();

  useEffect(async () => {
    const data = await getUserData(currentUser.email);
    // console.log(data.data());
    setData(data.data());
    // console.log(data.data());
    setshow(true);
    if (data.data().Verified == true) {
      setverifyMail(true);
    }
  }, []);

  async function handlePasswordReset(e) {
    e.preventDefault();
    try {
      setLoading(true);
      await resetPassword(currentUser.email);
      setalert(
        " Your passwork reset link has been sent to your email. Please check your inbox."
      );
      navigate("/profile");
    } catch {
      setalert(
        "Failed to send your password link. Please contact the system administrator"
      );
    }
    setreset(true);
    setLoading(false);
  }
  async function handleEmailVerification(e) {
    e.preventDefault();
    try {
      setLoading(true);
      await resetPassword(currentUser.email);
      setEmailalert(
        " Your passwork reset link has been sent to your email. Please check your inbox."
      );
      navigate("/profile");
    } catch {
      setEmailalert(
        "Failed to send your password link. Please contact the system administrator"
      );
    }
    setverifyMail(!verifyMail);
    setLoading(false);
  }
  async function updateProfile(e) {
    e.preventDefault();
    console.log(displayName.current.value);
    setLoading(true);
    try {
      await updateUser(displayName.current.value);
      setMainAlert("Your profile has been updated!");
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }
  return (
    <>
      <Container fluid="lg" className="py-5">
        {MainAlert ? (
          <>
            <Alert>{MainAlert}</Alert>
          </>
        ) : (
          <></>
        )}
        <Container fluid="lg" className="p-2">
          {show ? (
            <>
              <Form className="p-2">
                <Form.Group>
                  <Form.Text>Email address</Form.Text>
                  <Form.Control
                    type="email"
                    value={Data.Email}
                    readOnly
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Text>Display name</Form.Text>
                  <Form.Control
                    type="text"
                    placeholder={Data.Display}
                    ref={displayName}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Text>Email verified?</Form.Text>
                  <Form.Control
                    type="boolean"
                    value={Data.Verified}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Form>
              <ButtonGroup className="w-100">
                <Button onClick={updateProfile}>Save</Button>
              </ButtonGroup>
            </>
          ) : (
            <></>
          )}
        </Container>
        <Card className="text-center p-2">
          {verifyMail ? (
            <>
              {Emailalert ? (
                <>
                  <Card.Body>
                    <Card.Title>Email verification</Card.Title>
                    <Alert variant="success">{Emailalert}</Alert>
                  </Card.Body>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <Card.Body>
                <Card.Title>Verify email address</Card.Title>
                <Card.Text>
                  Verify your password using your email, click the button below.
                </Card.Text>
              </Card.Body>
              <Card.Footer className="text-muted">
                <Button
                  disabled={Loading}
                  onClick={handleEmailVerification}
                  variant="primary"
                >
                  Verify email address
                </Button>
              </Card.Footer>
            </>
          )}
        </Card>
        <Card className="text-center">
          {reset ? (
            <>
              {alert ? (
                <>
                  {" "}
                  <Card.Body>
                    <Card.Title>Password reset</Card.Title>
                    <Alert variant="success">{alert}</Alert>
                  </Card.Body>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <Card.Body>
                <Card.Title>Password reset</Card.Title>
                <Card.Text>
                  If you would like to reset your password using your email,
                  click the button below.
                </Card.Text>
              </Card.Body>
              <Card.Footer className="text-muted">
                <Button
                  disabled={Loading}
                  onClick={handlePasswordReset}
                  variant="primary"
                >
                  Reset password
                </Button>
              </Card.Footer>
            </>
          )}
        </Card>
      </Container>
    </>
  );
}
