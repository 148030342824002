import React, { useEffect, useState } from "react";
import { Table, Container, Button, Form, Alert } from "react-bootstrap";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { addLog, storage } from "../../firebase";
import {
  faFile,
  faFolder,
  faArrowDown,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../firebaseContext";

export default function ViewFiles() {
  var listRef = ref(storage, "Downloadable Files");
  const [folders, setfolders] = useState([]);
  const [files, setfiles] = useState([]);
  const { currentUser, admin } = useAuth();
  const navigate = useNavigate();
  const [showFolders, setshowFolders] = useState(true);
  const colors = ["orange", "purple", "indigo", "red", "pink"];
  function randomColor() {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  useEffect(() => {
    setfolders([]);
    setfiles([]);

    listAll(listRef)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          setfolders((folders) => [...folders, [folderRef.name, folderRef]]);
        });
        res.items.forEach((itemRef) => {
          getDownloadURL(itemRef).then((url) => {
            setfiles((files) => [
              ...files,
              [itemRef.name, url, itemRef.parent],
            ]);
          });
        });
      })

      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  }, []);

  function handleSelectFolder(value) {
    // console.log(value);
    navigate("/folder/" + value.name, {
      state: {
        folder: value.fullPath,
      },
    });
  }

  function handleDownloadButton(filename, fileURL) {
    addLog(currentUser.email, filename, "Downloaded");
    const anchor = document.createElement("a");
    anchor.href = fileURL;
    anchor.click();
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
  function sortFunction(a, b) {
    if (a[0] === b[0]) {
      return 0;
    } else {
      return a[0] < b[0] ? -1 : 1;
    }
  }
  function searchTable(e) {
    e.preventDefault();
    console.log(e.target.value);
    const data = document.querySelectorAll("#filenames");
    data.forEach((field) => {
      if (field.innerText.includes(e.target.value)) {
        console.log(field);
      }
    });
  }

  function handleShowFolders(e) {
    e.preventDefault();
    setshowFolders(!showFolders);
  }
  function handleAdminButton(e) {
    e.preventDefault();
    if (e.target.id == "addFolder") {
      navigate("/addFolder", {
        state: {
          path: listRef.fullPath,
          type: "files",
        },
      });
    }
  }

  return (
    <>
      {admin ? (
        <Container fluid="lg" className="d-flex justify-content-center p-2">
          {/* <h3 className="m-2">Admin Tools</h3> */}
          <Button
            onClick={handleAdminButton}
            size="sm"
            variant="danger"
            id="addFolder"
            className="mx-2 my-1 w-40"
          >
            Create&nbsp;a&nbsp;folder&nbsp;+&nbsp;Add&nbsp;files&nbsp;here
          </Button>
        </Container>
      ) : (
        ""
      )}
      <Container fluid="lg" className="mt-2">
        <Container className="d-flex">
          {/* <SideBar /> */}
          {/* <SubNav /> */}
        </Container>
        <Container fluid className="mt-2 text-center">
          {folders[0] ? (
            <Button onClick={handleShowFolders} className="mb-3 w-100">
              Folders&nbsp;
              {showFolders ? (
                <>
                  <FontAwesomeIcon icon={faArrowUp} />
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faArrowDown} />
                </>
              )}
            </Button>
          ) : (
            ""
          )}
          {showFolders ? (
            <>
              {" "}
              {folders.map(function (e) {
                return (
                  <Button
                    key={e.Id}
                    variant={"secondary"}
                    className="mx-2 my-1 text-truncate w-40 text-white"
                    onClick={(event) => {
                      event.preventDefault();
                      handleSelectFolder(e[1]);
                    }}
                  >
                    <FontAwesomeIcon icon={faFolder} />
                    &nbsp;{e[0]}
                  </Button>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </Container>
        <Container fluid className="p-2">
          {files.length == 0 ? (
            <></>
          ) : (
            <>
              <Form.Label>Search</Form.Label>
              <Form.Control type="text" onInput={searchTable} />
            </>
          )}
          {files.length > 0 ? (
            <>
              <Table className="text-left" striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>File name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {files.map(function (e) {
                    {
                      files.sort(sortFunction);
                    }
                    {
                      // console.log(Fake.sort());
                    }
                    return (
                      <>
                        <tr id="filenames">
                          <td>
                            <FontAwesomeIcon icon={faFile} />
                            &nbsp;{e[0]}
                          </td>
                          <td className="text-center">
                            <Button
                              onClick={() => handleDownloadButton(e[0], e[1])}
                            >
                              Download
                            </Button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <>
              <Alert className="text-center">
                There are no files in this folder
              </Alert>
            </>
          )}
        </Container>
      </Container>
    </>
  );
}
