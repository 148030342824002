import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Alert,
  Container,
  ButtonGroup,
  Table,
} from "react-bootstrap";
import { useAuth } from "../../firebaseContext";
import { useNavigate } from "react-router-dom";

export default function ManageUsers() {
  const navigate = useNavigate();
  const { getUsersCollection, resetPassword } = useAuth();

  const [showUsers, setshowUsers] = useState(false);
  const [showConfirm, setshowConfirm] = useState(false);
  const [alert, setalert] = useState(null);
  const [holdAction, setholdAction] = useState(null);
  const [userData, setuserData] = useState(null);

  useEffect(async () => {
    const data = await getUsersCollection();
    setuserData(data);
    // console.log(userData);
    setshowUsers(true);
  }, []);

  async function handleConfirmAction(e) {
    e.preventDefault();
    // console.log(holdAction.target);
    if (e.target.id == "confirm") {
      if (holdAction.target.name == "resetPassword") {
        try {
          resetPassword(holdAction.target.id);
          setalert(
            "Your request to send a password reset link to " +
              holdAction.target.id +
              " has been done!"
          );
        } catch (err) {
          setalert(err);
        }
      }
      setshowConfirm(false);
    } else {
      setshowConfirm(false);
    }
  }
  async function handleActions(e) {
    e.preventDefault();
    // console.log(e.target.id);
    // console.log(e.target.name);
    setholdAction(e);
    setshowConfirm(true);
  }
  async function handleUserProfile(e) {
    e.preventDefault();
    navigate("/user", {
      state: {
        email: e.target.id,
      },
    });
  }

  return (
    <Container fluid className="text-center">
      <Alert>User data will appear/update after they log in..</Alert>
      {showUsers ? (
        <>
          <Card className="py-3">
            <h2>Manage users - {userData.length}</h2>
            {showConfirm ? (
              <>
                {" "}
                <Container id="confirm">
                  <Card className="py-3">
                    <Card.Title>Are you sure you want to do this?</Card.Title>
                    <ButtonGroup className="p-2">
                      <Button
                        id="confirm"
                        onClick={handleConfirmAction}
                        variant="success"
                      >
                        Confirm
                      </Button>
                      <Button
                        id="cancel"
                        onClick={handleConfirmAction}
                        variant="danger"
                      >
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </Card>
                </Container>
              </>
            ) : (
              <></>
            )}
            {alert ? (
              <>
                <Alert variant="success">{alert}</Alert>
                <Button
                  onClick={(e) => {
                    setalert(null);
                  }}
                >
                  Close
                </Button>
              </>
            ) : (
              <></>
            )}
          </Card>
          <Container fluid="lg" className="p-1">
            <Table striped bordered fluid responsive>
              <thead>
                <tr>
                  <th>Email address</th>
                  <th>Action 1</th>
                  <th>Action 2</th>
                </tr>
              </thead>
              <tbody>
                {userData.map((e) => {
                  return (
                    <>
                      <tr>
                        <td>{e[0]}</td>
                        <td>
                          <Button
                            onClick={handleUserProfile}
                            name="ViewProfile"
                            id={e[0]}
                          >
                            View&nbsp;Profile
                          </Button>
                        </td>
                        <td>
                          <Button
                            onClick={handleActions}
                            variant="secondary"
                            name="resetPassword"
                            id={e[0]}
                          >
                            Reset&nbsp;Password
                          </Button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </Container>
        </>
      ) : (
        <>
          <h2>Loading..</h2>
        </>
      )}
    </Container>
  );
}
