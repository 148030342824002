import React, { useEffect, useRef, useState } from "react";
import { Container, Form, Button, Alert, ButtonGroup } from "react-bootstrap";
import { useAuth } from "../../firebaseContext";
import { useNavigate, useLocation } from "react-router-dom";
import TrainingModule from "../TrainingModule";

export default function Training() {
  const [check, setcheck] = useState(false);
  const [loaded, setloaded] = useState(false);

  function checkvalue(e) {
    e.preventDefault();
    console.log(check);
  }
  return (
    <>
      <TrainingModule />{" "}
      <Container className="">
        {/* <iframe
        hidden={!loaded}
        src="https://1drv.ms/p/c/74304da80050f6ef/IQPyK1aoMROcTI33JJkpITp5AeECHc61y1Uvag454PfzZRU?em=2&amp;wdAr=1.7777777777777777&amp;wdEaaCheck=0"
        width="100%"
        height="400"
        onLoad={(e) => setloaded(true)}
      ></iframe>
      {loaded ? (
        <></>
      ) : (
        <>
          <Alert variant="red" className="text-center">
            Wait while the document loads...
          </Alert>
        </>
      )} */}
        {/* <Form className="p-2">
        <Form.Group className="d-flex flex-column">
          <Alert className="w-100 text-center">
            Are you able to view the presentation?
          </Alert>
          <Container className="d-flex">
            <ButtonGroup className="w-100 p-2 d-flex">
              <Button
                variant={"orange"}
                value={"yes"}
                className="mx-1 text-white"
                onClick={(e) => {
                  setcheck(true);
                }}
              >
                Yes
              </Button>
              <Button
                variant={"danger"}
                value={"no"}
                className="mx-1 text-white"
                onClick={(e) => {
                  setcheck(false);
                }}
              >
                No
              </Button>
            </ButtonGroup>
          </Container>
          {/* <Button onClick={checkvalue}>Check</Button> */}
        {/* {check ? (
            <Container className="p-2 w-100 d-flex">
              <Button className="w-100" variant="primary" href="/questionnaire">
                Continue...
              </Button>
            </Container>
          ) : (
            <></>
          )}
        </Form.Group>
      </Form> */}
      </Container>{" "}
    </>
  );
}
