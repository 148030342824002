import { ref, listAll, getDownloadURL } from "firebase/storage";
import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Alert,
  Button,
  Form,
  Table,
  ButtonGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faFolder,
  faArrowDown,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";

import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../firebaseContext";
import { addLog, storage } from "../../firebase";

export default function Folder() {
  const navigate = useNavigate();
  const uselocation = useLocation();
  const { currentUser, admin } = useAuth();
  var folderRef;
  const [listRef, setlistRef] = useState("");

  const [folder, setfolder] = useState(null);
  const [Refresh, setRefresh] = useState(false);
  const [folders, setfolders] = useState([]);
  const [files, setfiles] = useState([]);
  const [filterFiles, setfilterFiles] = useState([]);
  const [search, setsearch] = useState(false);
  const searchText = useRef();
  const [showFolders, setshowFolders] = useState(true);

  useEffect(() => {
    uselocation.state.folder ? setfolder(uselocation.state.folder) : <></>;
    uselocation.state.refresh ? setRefresh(uselocation.state.folder) : <></>;
    // console.log(uselocation.state.folder);
    if (Refresh == true) {
      navigate(0);
    }
    // console.log(location.state.folder);
    const newRef = ref(storage, uselocation.state.folder);
    // console.log(newRef);
    folderRef = newRef;
    setlistRef(newRef.fullPath);
  }, []);

  useEffect(() => {
    setfiles([]);
    setfolders([]);
    listAll(folderRef).then((res) => {
      res.prefixes.forEach((folderRef) => {
        setfolders((folders) => [...folders, [folderRef.name, folderRef]]);
      });
      res.items.forEach((itemRef) => {
        getDownloadURL(itemRef).then((url) => {
          setfiles((files) => [...files, [itemRef.name, url, itemRef.parent]]);
        });
      });
    });
  }, []);

  function handleDownloadButton(filename, fileURL) {
    addLog(currentUser.email, filename, "Downloaded");
    const anchor = document.createElement("a");
    anchor.href = fileURL;
    anchor.click();
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
  function sortFunction(a, b) {
    if (a[0].toLowerCase() === b[0].toLowerCase()) {
      return 0;
    } else {
      return a[0].toLowerCase() < b[0].toLowerCase() ? -1 : 1;
    }
  }
  function searchTable(e) {
    e.preventDefault();
    // console.log(e.target.value);
    setfilterFiles([]);
    if (searchText.current.value == "") {
      setsearch(false);
    } else {
      files.forEach((file) => {
        // console.log(file);
        if (
          file[0].toLowerCase().includes(searchText.current.value.toLowerCase())
        ) {
          setfilterFiles((Ffiles) => [...Ffiles, file]);
        }
      });
      console.log(filterFiles);
      setsearch(true);
    }
  }
  function handleCancelSearch(e) {
    e.preventDefault();
    searchText.current.value = "";
    setsearch(false);
  }

  function handleSelectFolder(value) {
    // console.log(value.fullPath);
    navigate("/folder/" + value.name, {
      state: {
        folder: value.fullPath,
      },
    });
    navigate(0);
  }
  function handleShowFolders(e) {
    e.preventDefault();
    setshowFolders(!showFolders);
  }
  function handleAdminButton(e) {
    e.preventDefault();
    console.log(folderRef);
    if (e.target.id == "addFolder") {
      navigate("/addFolder", {
        state: {
          path: listRef,
          type: "files",
        },
      });
    }
  }

  return (
    <Container fluid="lg" className="p-2">
      <Container fluid="lg" className="mt-2">
        <Container className="d-flex">
          {/* <SideBar /> */}
          {/* <SubNav /> */}
        </Container>
        {admin ? (
          <Container fluid="lg" className="d-flex justify-content-center p-2">
            {/* <h3 className="m-2">Admin Tools</h3> */}
            <Button
              onClick={handleAdminButton}
              size="sm"
              variant="danger"
              id="addFolder"
              className="mx-2 my-1 w-40"
            >
              Create&nbsp;a&nbsp;folder&nbsp;+&nbsp;Add&nbsp;files&nbsp;here
            </Button>
          </Container>
        ) : (
          ""
        )}
        <Container fluid className=" d-flex flex-wrap mt-2 text-center">
          {folders[0] ? (
            <Button onClick={handleShowFolders} className="my-1 w-100">
              Folders&nbsp;
              {showFolders ? (
                <>
                  <FontAwesomeIcon icon={faArrowUp} />
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faArrowDown} />
                </>
              )}
            </Button>
          ) : (
            <Button
              variant="dark"
              className="text-white w-100"
              onClick={() => {
                navigate("/downloads");
              }}
            >
              Go back to Downloads page
            </Button>
          )}
          {showFolders ? (
            <>
              {folders.map(function (e) {
                {
                  folders.sort(sortFunction);
                }
                return (
                  <Button
                    key={e.Id}
                    variant={"secondary"}
                    className="mx-2 my-1 text-truncate w-40 text-white"
                    onClick={(event) => {
                      event.preventDefault();
                      handleSelectFolder(e[1]);
                    }}
                  >
                    <FontAwesomeIcon icon={faFolder} />
                    &nbsp;{e[0]}
                  </Button>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </Container>
        <Container fluid className="d-flex flex-column mt-2">
          {files.length == 0 ? (
            <></>
          ) : (
            <>
              <Form onSubmit={searchTable}>
                <Form.Group className="d-flex align-items-center justify-content-center w-100">
                  <Form.Control
                    type="text"
                    id="searchField"
                    placeholder="Search.."
                    className="p-2"
                    ref={searchText}
                  />
                  <ButtonGroup className="p-2">
                    <Button
                      variant="success"
                      // onClick={searchTable}
                      type="submit"
                      className="m-1 text-white"
                    >
                      Search
                    </Button>
                    <Button
                      variant="danger"
                      onClick={handleCancelSearch}
                      className="m-1"
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Form.Group>
              </Form>
            </>
          )}
          <Table className="text-left" fluid striped bordered hover responsive>
            <thead>
              <tr>
                <th>File name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {search ? (
                <>
                  {filterFiles.map(function (e) {
                    {
                      filterFiles.sort(sortFunction);
                    }
                    {
                      // console.log(Fake.sort());
                    }
                    return (
                      <>
                        <tr id="filenames">
                          <td className="text-justify">
                            <FontAwesomeIcon icon={faFile} />
                            &nbsp;{e[0]}
                          </td>
                          <td className="text-center">
                            <Button
                              onClick={() => handleDownloadButton(e[0], e[1])}
                            >
                              Download
                            </Button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  {files.map(function (e) {
                    {
                      files.sort(sortFunction);
                    }
                    {
                      // console.log(Fake.sort());
                    }
                    return (
                      <>
                        <tr id="filenames">
                          <td>
                            <FontAwesomeIcon icon={faFile} />
                            &nbsp;{e[0]}
                          </td>
                          <td className="text-center">
                            <Button
                              onClick={() => handleDownloadButton(e[0], e[1])}
                            >
                              Download
                            </Button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </>
              )}
            </tbody>
          </Table>
        </Container>
      </Container>

      {folder ? (
        <></>
      ) : (
        <>
          <Alert variant="danger">Code 404. Page not found!</Alert>
          <Alert variant="info">
            Note : Copying the URL from address bar doesn't work for file system{" "}
          </Alert>
          <Button
            onClick={(e) => {
              navigate("/downloads");
            }}
            className="w-100"
          >
            Head to Downloads page
          </Button>
        </>
      )}
    </Container>
  );
}
