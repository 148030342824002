import React, { useEffect, useState, useRef } from "react";
import { Container, Table, Button, ButtonGroup, Form } from "react-bootstrap";
import { getFilesIndex } from "../../firebase";
import { useNavigate } from "react-router-dom";

export default function Index() {
  const navigate = useNavigate();
  const [indexFiles, setindexFiles] = useState([]);
  const [showData, setshowData] = useState(false);
  const [filterFiles, setfilterFiles] = useState([]);
  const [search, setsearch] = useState(false);
  const searchText = useRef();
  // const [maxItems, setmaxItems] = useState(25);

  useEffect(async () => {
    setindexFiles([]);
    const data = await getFilesIndex();
    // console.log(data.docs); //array of docs
    data.docs.forEach((doc) => {
      //   console.log(doc.data());  // each document data
      setindexFiles((files) => [...files, doc.data()]);
    });
    setshowData(true);
  }, []);

  function sortFunction(a, b) {
    if (a.filename.toLowerCase() === b.filename.toLowerCase()) {
      return 0;
    } else {
      return a.filename.toLowerCase() < b.filename.toLowerCase() ? -1 : 1;
    }
  }
  function searchTable(e) {
    e.preventDefault();
    // console.log(e.target.value);
    setfilterFiles([]);
    if (searchText.current.value == "") {
      setsearch(false);
    } else {
      indexFiles.forEach((file) => {
        // console.log(file.filename); // works
        if (
          file.filename
            .toLowerCase()
            .includes(searchText.current.value.toLowerCase())
        ) {
          setfilterFiles((Ffiles) => [...Ffiles, file]);
        }
      });
      console.log(filterFiles);
      setsearch(true);
    }
  }
  function handleCancelSearch(e) {
    e.preventDefault();
    searchText.current.value = "";
    setsearch(false);
  }
  function handleNavigate(e, folderPath) {
    e.preventDefault();
    const folderArray = folderPath.split("/");
    const path = folderArray[folderArray.length - 1];
    // console.log(path);
    if (folderPath.toLowerCase().includes("viewable files")) {
      navigate("/file/" + path, {
        state: {
          fullPath: folderPath,
        },
      });
    } else {
      navigate("/folder/" + path, {
        state: {
          folder: folderPath,
        },
      });
    }
  }
  return (
    <Container fluid="lg">
      <Form onSubmit={searchTable}>
        <Form.Group className="d-flex align-items-center justify-content-center w-100">
          <Form.Control
            type="text"
            id="searchField"
            placeholder="Search.."
            className="p-2"
            ref={searchText}
          />
          <ButtonGroup className="p-2">
            <Button
              variant="success"
              // onClick={searchTable}
              type="submit"
              className="m-1 text-white"
            >
              Search
            </Button>
            <Button
              variant="danger"
              onClick={handleCancelSearch}
              className="m-1"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Form.Group>
      </Form>
      <Table striped bordered responsive="sm" className="p-2 my-2">
        <thead className="text-left">
          <th>Filename</th>
          <th>Location</th>
        </thead>
        <tbody className="text-left">
          {showData ? (
            <>
              {search ? (
                <>
                  {filterFiles.map(function (e) {
                    return (
                      <>
                        <tr>
                          <td className="w-50">{e.filename}</td>
                          <td className="w-50">
                            <Button>{e.location}</Button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  {indexFiles.map((file) => {
                    {
                      indexFiles.sort(sortFunction);
                    }
                    return (
                      <>
                        <tr key={Math.floor(Math.random() * indexFiles.length)}>
                          <td className="text">{file.filename}</td>
                          <td className="text-truncate">
                            <Button
                              onClick={(e) => handleNavigate(e, file.location)}
                            >
                              {file.location}
                            </Button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </tbody>
      </Table>
    </Container>
  );
}
