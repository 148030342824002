import React, { useEffect, useState } from "react";
import { Container, Button, ButtonGroup, Alert, Image } from "react-bootstrap";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { storage } from "../../firebase";
import { useLocation, useNavigate } from "react-router-dom";

export default function File() {
  const location = useLocation();
  const fullPath = location.state.fullPath;
  const listRef = ref(storage, fullPath);
  const [files, setfiles] = useState([]);
  const navigate = useNavigate();
  const [currentFile, setcurrentFile] = useState(0);
  const [ready, setready] = useState(false);

  useEffect(async () => {
    // console.log(fullPath);
    setfiles([]);
    listAll(listRef).then((res) => {
      res.items.forEach((itemRef) => {
        getDownloadURL(itemRef).then((url) => {
          setfiles((files) => [...files, [itemRef.name, url, itemRef.parent]]);
          setready(true);
        });
      });
    });
  }, []);

  function handleNav(e) {
    // console.log(files.length);
    e.preventDefault();
    files.sort(sortFunction);
    if (e.target.id == "Previous") {
      setready(false);
      setcurrentFile(currentFile - 1);
      setready(true);
    } else if (e.target.id == "Next") {
      setready(false);
      setcurrentFile(currentFile + 1);
      setready(true);
    }
  }
  function sortFunction(a, b) {
    if (a[0] === b[0]) {
      return 0;
    } else {
      return a[0] < b[0] ? -1 : 1;
    }
  }

  return (
    <Container fluid="lg">
      {ready ? (
        <>
          <Container fluid className="p-2">
            <ButtonGroup className="d-flex flex-column justify-content-between">
              <ButtonGroup>
                <Button
                  onClick={() => navigate(-1)}
                  size="sm"
                  variant="info"
                  className="text-white m-1"
                >
                  Back
                </Button>
              </ButtonGroup>
              <ButtonGroup className="">
                <Button
                  id="Previous"
                  size="sm"
                  onClick={handleNav}
                  disabled={currentFile == 0}
                  className="mx-1"
                >
                  Previous
                </Button>
                <Button
                  id="Next"
                  size="sm"
                  onClick={handleNav}
                  disabled={currentFile + 1 == files.length}
                  className="mx-1"
                >
                  Next
                </Button>
              </ButtonGroup>
            </ButtonGroup>
            {files[0] ? (
              <>
                {files.map((f) => {})}
                <Container className="p-1 d-flex flex-column align-items-center">
                  <Alert className="text-center mt-2 w-100">
                    File :{files[currentFile][0]}
                  </Alert>
                  <Image id="ViewFilesImage" src={files[currentFile][1]} />
                </Container>
              </>
            ) : (
              <></>
            )}
          </Container>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
}
