import React, { useRef, useState } from "react";
import { Form, Button, Alert, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../firebaseContext";

export default function CreateUserFromLink() {
  const passRef = useRef();
  const { confirmPasswordLink } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [Verified, setVerified] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      //   const linkVerified = await checkLoginLink(window.location.href);
      console.log(window.location.href);
      //   if (linkVerified) {
      //     try {
      //       await loginFromEmailLink(passRef.current.value, window.location.href);
      //       navigate("/profile");
      //     } catch {
      //       setError("Link not compatiable with the provided email!");
      //     }
      //   }
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  }

  return (
    <>
      <Container className="container-sm py-5">
        <h2 className="text-center mb-4">Welcome to Nobility Care Australia</h2>
        <h4 className="text-center mb-4">Enter your new password</h4>
        {error && <Alert variant="danger">{error}</Alert>}
        {Verified ? (
          <>
            <Alert variant="success" className="text-center">
              {" "}
              Your email has been verified!
            </Alert>
            <Alert
              variant="dark"
              className="d-flex flex-column justify-content-around align-items-center"
            >
              If this is your first time logging in, head to profile page using
              this button and reset your password for future access
              <Button onClick={() => navigate("/profile")} className="m-2">
                Profile page
              </Button>
            </Alert>
          </>
        ) : (
          <>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mt-3" id="email">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  ref={passRef}
                  required
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Button disabled={loading} className="w-100" type="submit">
                  Change Password
                </Button>
              </Form.Group>
            </Form>
          </>
        )}
      </Container>
    </>
  );
}
