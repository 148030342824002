import React from "react";
import { NavDropdown, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import QuickLinkItem from "../QuickLinkItem";

export default function QuickLinks() {
  return (
    <NavDropdown
      as={Button}
      active
      variant="info"
      className="p-1 m-1"
      title="Quick Links"
      id="sidebar"
    >
      <QuickLinkItem
        link={"http://www.nobilitycare.com.au/"}
        itemname={"Nobility Care Australia - Website"}
      />
      <QuickLinkItem
        link={
          "https://nobilitycare.foliogrc.com/launchpad?token=2iLErMuskjKcMAB6CzkZ"
        }
        itemname={"Staff links - Launchpad"}
      />
    </NavDropdown>
  );
}
