import React, { useRef, useState } from "react";
import {
  Container,
  Button,
  Form,
  ButtonGroup,
  ProgressBar,
} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { ref } from "firebase/storage";
import {
  storage,
  uploadFilesTask,
  uploadImagesFolder,
  uploadImagesTask,
} from "../../firebase";

export default function AddFolder() {
  const navigate = useNavigate();
  const location = useLocation();
  const DownloadableFiles = location.state.type == "files" ? true : false;
  const folderName = useRef();
  const files = useRef();
  const path = location.state.path + "/";
  const [isUploading, setisUploading] = useState(false);
  var currentFileUpload = 0;
  const [progress, setprogress] = useState(0);
  console.log(location);

  async function handleNewFolder() {
    if (DownloadableFiles) {
      for (let index = 0; index < files.current.files.length; index++) {
        setisUploading(true);
        const pathName =
          path +
          folderName.current.value +
          "/" +
          files.current.files[index].name;
        const currentRef = ref(storage, pathName);
        currentFileUpload++;
        await uploadFilesTask(currentRef, files.current.files[index]);
        setprogress((currentFileUpload / files.current.files.length) * 100);
        setisUploading(false);
        // console.log(progress);
      }
    } else {
      await uploadImagesFolder(ref(storage, path + folderName.current.value));
      for (let index = 0; index < files.current.files.length; index++) {
        setisUploading(true);
        const pathName =
          path +
          folderName.current.value +
          "/" +
          files.current.files[index].name;
        const currentRef = ref(storage, pathName);
        currentFileUpload++;
        await uploadImagesTask(currentRef, files.current.files[index]);
        setprogress((currentFileUpload / files.current.files.length) * 100);
        setisUploading(false);
        // console.log(progress);
      }
    }
  }

  //   console.log(path);
  return (
    <>
      <Container className="p-2">
        <h3 className="text-center my-2">Add a folder with files</h3>
        <Form>
          <Form.Group>
            <Form.Label>New folder name</Form.Label>
            <Form.Control type="text" ref={folderName}></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Select files -</Form.Label>
            {DownloadableFiles ? (
              <>
                <Form.Control
                  id="inputFiles"
                  type="file"
                  multiple
                  ref={files}
                />
              </>
            ) : (
              <>
                <Form.Control
                  id="inputImages"
                  type="file"
                  multiple
                  accept="image/*"
                  ref={files}
                />
              </>
            )}
          </Form.Group>
          <ButtonGroup className="d-flex my-2">
            <Button
              disabled={isUploading}
              onClick={handleNewFolder}
              variant="primary"
            >
              {isUploading ? "Uploading" : "Upload Files"}
            </Button>
          </ButtonGroup>
        </Form>
        {isUploading ? (
          <>
            <Container>
              {/* <h4>
                Files uploaded : {currentFileUpload} /{" "}
                {files.current.files.length}
              </h4> */}
              <ProgressBar striped variant="danger" now={progress} />
            </Container>
          </>
        ) : (
          ""
        )}
        {progress == 100 ? (
          <>
            <Container className="d-flex flex-column justify-content-center text-center">
              <h5>Upload completed!</h5>
              <Button
                onClick={(e) => {
                  navigate(-1);
                }}
                variant="teal"
                className="text-white"
              >
                Go Back
              </Button>
            </Container>
          </>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
}
